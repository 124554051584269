import * as style from "./quotation.module.scss"

import React, { useState } from "react"

import loadable from "@loadable/component"
import { StaticImage } from "gatsby-plugin-image"
import {
  getAllLocales,
  getCurrentLanguagePage,
  validateBirthday,
  getAge,
  formValidation,
  isHK,
  updateUrlByLocale,
  isEmailValid,
} from "../../plugins/common"
import { staticText } from "../../enumerator/staticText"
import { pipedriveValue } from "../../enumerator/pipedriveValue"

import Layout from "../../components/Layout"
import Progress from "../../components/quotation/Progress"
import StepBenefits from "../../components/quotation/Benefits"
import StepQuantity from "../../components/quotation/Quantity"

import glossaryHeaderBg from "../../images/glossary/bg.webp"
import StepBirth from "../../components/quotation/Birth"
import StepResidence from "../../components/quotation/Residence"
import StepNonHK from "../../components/quotation/NonHK"
import StepThankyou from "../../components/quotation/Thankyou"
import { navigate } from "gatsby-link"
import { createPipedriveBody } from "../../DTO/eqtDTO"
import { createPipedriveEntry } from "../../DAO/eqtDAO"
import { benefitId } from "../../enumerator/benefitId"
import Loading from "../../components/Loading"
import SnackBar from "../../components/SnackBar"
import StepEmail from "../../components/quotation/StepEmail"
import { createEqtLog, updateEqtLog } from "./hooks"
// const Banner = loadable(() => import("../../components/Banner"))
const Resources = loadable(() => import("../../components/homepage/Resources"))
const Pros = loadable(() => import("../../components/homepage/Pros"))
const Process = loadable(() => import("../../components/homepage/Process"))
const Partners = loadable(() => import("../../components/homepage/Partners"))
const Testimonial = loadable(() =>
  import("../../components/homepage/Testimonial")
)

const leftEmpty = "../../images/quotation/left-empty.png"
const leftFull = "../../images/quotation/left-full.png"
const shape_right = "../../images/quotation/right-shapes.png"

const QuotationPage = ({ pageContext, location }) => {
  const { form: quoteForm, node: data, general, tool } = pageContext
  const {
    // title,
    meta,
    localizations,
    locale,
  } = data

  const homepageData = pageContext.homepage[0]
  const generalData = general.allStrapiGeneral.nodes[0]
  const toolData = tool
  const keyLabels = general.allStrapiRessource.nodes[0]
  const languageOptions = general.allStrapiLanguages?.nodes ?? "en"
  const lang = getCurrentLanguagePage(location, languageOptions)
  const aleaToken = general.aleaToken
  const enableFooterForm = data.footerForm?.enableFooter
  const footerForm = {
    ...data.footerForm,
    ...quoteForm.freeQuote,
  }

  // console.log("data: ", data)
  // console.log("toolData: ", toolData)

  const headerTitle = data.headerTitle
  const benefitList = data.coverageSelection.coverage
  benefitList.map(item => (item.selected = item.default))

  const invalidEmailMsg = toolData.stepEmail.invalidEmailMsg
  const emailPh = toolData.stepEmail.emailPlaceholder
  const emailTnc = toolData.stepEmail.emailTnc
  const invalidTncMsg = toolData.stepEmail.invalidTncMsg

  // const stepEmailTitle = "Enter your email"
  const stepTitle = [
    data.emailProtection.title,
    data.coverageSelection.title,
    data.whoToInsure.title,
    data.whereLive.title,
    toolData.nonHKFlow.title,
    toolData.thankYouTitle,
  ]

  const progressLabels = {
    backLabel: toolData.backLabel,
    nextLabel: toolData.nextLabel,
    submitLabel: toolData.submitLabel,
    applyNowLabel: toolData.applyNowLabel,
    insureTeamButton: data.insureTeam,
  }
  const whoToInsure = data.whoToInsure

  const birthLabels = {
    dateOfBirthLabel: data.yourFamily.dateOFBirthLabel,
    genderLabel: data.yourFamily.genderLabel,
    personLabel: data.yourFamily.personLabel,
    maleIcon: data.yourFamily.maleIcon,
    femaleIcon: data.yourFamily.femaleIcon,
  }

  const nonHKFormLabels = {
    subtitle: toolData.nonHKFlow.subtitle,
    fromLabels: toolData.form,
  }

  // const invalidGenderMsg = "Please select your gender"
  //const invalidBirthdayMsg = data.yourFamily.noBirthdayErrorMsg
  //const invalidPersonGenderMsg = data.yourFamily.noGenderErrorMsg

  const invalidBenefitMsg = data.coverageSelection.noBenefitErrorMsg
  const emptyResidenceMsg = data.whereLive.noPlaceErrorMsg
  const invalidFormMsg = toolData.form.invalidFormMsg
  const uncheckInpatientWarning = toolData.search.uncheckInpatientWarning

  const residencePlaceholder = data.whereLive.placeholder

  const thankSubtitle = toolData.nonHKFlow.thankYouSubtitle
  const thankContent = toolData.nonHKFlow.thankYouContent
  const resultPageSlug = "/" + toolData.slug
  const quotationForm = {
    firstName: "",
    familyName: "",
    countryCode: "+852",
    phone: "",
    email: "",
  }

  const [email, setEmail] = useState("")
  const [pdPerson, setPdPerson] = useState(null)
  const [pdLead, setPdLead] = useState(null)
  const [step, setStep] = useState(0)
  const [progressTitle, setProgressTitle] = useState(stepTitle[0])
  const [agreeTerms, setAgreeTerms] = useState(false)
  const [termsError, setTermsError] = useState(false)
  const [emailError, setEmailError] = useState(false)
  const [errorMessage, setErrorMessage] = useState("")
  // const [sex, setSex] = useState(null)
  const [benefits, setBenefits] = useState(benefitList)
  const [insureQuantity, setInsureQuantity] = useState(1)
  const [insurerDetails, setInsurerDetails] = useState([])
  const [residence, setResidence] = useState("")
  const form = quotationForm
  const [formError, setFormError] = useState({})
  const [submitLoading, setSubmitLoading] = useState(false)
  const [showSnackBar, setShowSnackBar] = useState(false)
  const [snackBarMessage, setSnackBarMessage] = useState("")

  const getSelectedBenefitIds = () => {
    // const isNotSure = benefits.find(
    //   ben => ben.pipedriveId === 999 && ben.selected
    // )

    return benefits
      .filter(benef => benef.selected)
      .map(benef => parseInt(benef.pipedriveId))
  }

  const updateBenefits = index => {
    if (parseInt(benefits[index].pipedriveId) === benefitId.IN_PATIENT) {
      clickOnInpatient()
      return
    }
    benefits[index].selected = !benefits[index].selected
    const newBenefits = JSON.parse(JSON.stringify(benefits))
    setBenefits(newBenefits)
  }

  const checkingStep3 = () => {
    let error = false
    insurerDetails.map(item => {
      const birthArr = item.birthday.split("/")
      item.yyyy = birthArr[2]
      item.mm = birthArr[1]
      item.dd = birthArr[0]
      // if (error) return
      if (
        !validateBirthday(
          parseInt(item.dd),
          parseInt(item.mm),
          parseInt(item.yyyy)
        )
      ) {
        error = true
        item.birthdayError = true
        // return
      } else {
        item.birthdayError = false
      }
      if (item.gender === null) {
        error = true
        item.genderError = true
      } else item.genderError = false
      return item
    })
    setInsurerDetails(JSON.parse(JSON.stringify(insurerDetails)))
    if (error) setErrorMessage(invalidFormMsg)
    return error
  }

  const updateStep = async newStep => {
    let title = stepTitle[newStep]
    let nextStep = newStep
    setSubmitLoading(true)

    if (step === 0) {
      if (!agreeTerms) {
        setTermsError(true)
        setErrorMessage(invalidTncMsg)
        setSubmitLoading(false)
        return
      }
      if (!isEmailValid(email)) {
        setEmailError(true)
        setErrorMessage(invalidEmailMsg)
        setSubmitLoading(false)
        return
      }
      setEmailError(false)
      setTermsError(false)
      setErrorMessage("")
      await createEqtLog(aleaToken, lang, { email }, { setPdPerson, setPdLead })
      //save client id
    }
    if (step === 1 && newStep === 2) {
      if (!getSelectedBenefitIds().length) {
        setErrorMessage(invalidBenefitMsg)
        setSubmitLoading(false)
        return
      }
      const selectedBenefits = getSelectedBenefitIds()

      await updateEqtLog(
        1,
        aleaToken,
        {
          pdPerson,
          pdLead,
          selectedBenefits,
        },
        { setPdPerson, setPdLead }
      )
    }
    if (step === 2 && newStep === 3) {
      if (checkingStep3()) {
        setSubmitLoading(false)
        return
      }
      await updateEqtLog(
        2,
        aleaToken,
        {
          pdPerson,
          pdLead,
          insurerDetails,
        },
        { setPdPerson, setPdLead }
      )
    }

    if (step === 3 && newStep === 4) {
      if (!residence || !residence.length) {
        setErrorMessage(emptyResidenceMsg)
        setSubmitLoading(false)
        return
      }
      await updateEqtLog(
        3,
        aleaToken,
        {
          pdPerson,
          pdLead,
          residence,
        },
        { setPdPerson, setPdLead }
      )
      if (!isHK(residence)) {
        title = stepTitle[newStep].replace("{{country}}", residence)
      } else {
        // step 8 : search page
        const options = {
          insurerDetails: insurerDetails,
          benefitIds: getSelectedBenefitIds(),
        }
        if (typeof window !== "undefined") {
          localStorage.setItem("searchOptions", JSON.stringify(options))
        }

        // const slugToGo = (locale === "en" ? "" : locale + "/") + resultPageSlug
        navigate(updateUrlByLocale(resultPageSlug, lang))
        setSubmitLoading(false)
        return
      }
    }

    setSubmitLoading(false)
    setErrorMessage("")
    setProgressTitle(title)
    setStep(nextStep)
  }

  const createClient = () => {
    // const allInsurers = insurerDetails.map(ele => {
    //   ele.dd = parseInt(ele.dd)
    //   ele.mm = parseInt(ele.mm)
    //   ele.yyyy = parseInt(ele.yyyy)
    //   return ele
    // })
    const clientInfo = insurerDetails[0]
    const otherInsurers = insurerDetails.filter((ele, i) => i !== 0)

    return createPipedriveBody(
      form,
      clientInfo,
      otherInsurers,
      getAge(clientInfo.dd, clientInfo.mm, clientInfo.yyyy),
      residence,
      getSelectedBenefitIds(),
      null,
      [],
      staticText.NONHK_NOTE,
      staticText.NONHK,
      [pipedriveValue.EQT_NONHK],
      lang,
      []
    )
  }
  const submitForm = async type => {
    setSubmitLoading(true)
    if (!formValidation(form, setFormError, setErrorMessage, invalidFormMsg)) {
      setSubmitLoading(false)
      return
    }
    if (type === staticText.NONHK) {
      const res = await createPipedriveEntry(aleaToken, createClient())

      setSubmitLoading(false)
      if (res) {
        //Thank you page
        setProgressTitle(stepTitle[4])
        setStep(5)
      }
    }
  }

  const clickOnInpatient = () => {
    setSnackBarMessage(uncheckInpatientWarning)
    setShowSnackBar(true)
  }

  // useEffect(() => {
  //   const userEmailItem = getLocalStorage("uem")
  //   if (userEmailItem?.email) setEmail(userEmailItem?.email)
  // }, [])

  return (
    <Layout
      props={pageContext.general}
      meta={meta}
      localizations={getAllLocales(localizations, locale)}
      location={location}
      hideNewsletter={true}
      hideLanguage={["fr"]}
      lang={lang}
      token={aleaToken}
      footerForm={enableFooterForm ? footerForm : null}
    >
      <div className={`${style.quotation} ${step >= 4 && "mb-0"}`}>
        {submitLoading && <Loading />}

        <SnackBar
          show={showSnackBar}
          setShow={setShowSnackBar}
          message={snackBarMessage}
          timeout={2000}
        />
        <div
          className={`container-fluid text-center ${style.header}  position-relative`}
          style={{ backgroundImage: `url(${glossaryHeaderBg})` }}
        >
          <StaticImage
            sizes="(max-width: 370px) 37w, (max-width: 500px) 50vw,(max-width: 800px) 80vw,  100vw"
            className={`${style.leftEmpty} position-absolute`}
            src={leftEmpty}
            alt=""
            layout="constrained"
          />
          <StaticImage
            sizes="(max-width: 370px) 37w, (max-width: 500px) 50vw,(max-width: 800px) 80vw,  100vw"
            className={`${style.leftFull} position-absolute`}
            src={leftFull}
            alt=""
            layout="constrained"
          />
          <StaticImage
            sizes="(max-width: 370px) 37w, (max-width: 500px) 50vw,(max-width: 800px) 80vw,  100vw"
            className={`${style.right} position-absolute d-lg-block d-none`}
            src={shape_right}
            alt=""
            layout="constrained"
          />
          <h1 className="ming">{headerTitle}</h1>
        </div>
        <div className={`${style.quotationBody} position-relative`}>
          <div
            className={`container ${
              style.content
            } card soft-corners soft-shadows ${step >= 4 && "mb-5"}`}
          >
            <Progress
              step={step}
              updateStep={updateStep}
              insureQuantity={insureQuantity}
              progressTitle={progressTitle}
              submitForm={submitForm}
              labels={progressLabels}
            >
              {step !== 2 && (
                <div
                  className={`${style.errorMsg} ${
                    errorMessage.length > 0 ? "visible" : "invisible"
                  }`}
                >
                  {errorMessage}
                </div>
              )}

              {/* {step === 1 && <Gender sex={sex} updateSex={setSex} />} */}
              {step === 0 && (
                <StepEmail
                  email={email}
                  setEmail={setEmail}
                  termsError={termsError}
                  emailError={emailError}
                  agreeTerms={agreeTerms}
                  setAgreeTerms={setAgreeTerms}
                  emailTnc={emailTnc}
                  placeholder={emailPh}
                />
              )}
              {step === 1 && (
                <StepBenefits
                  benefitList={benefits}
                  updateBenefits={updateBenefits}
                />
              )}
              {step === 2 && (
                <div className="d-lg-flex row mt-3">
                  <StepQuantity
                    insureQuantity={insureQuantity}
                    setInsureQuantity={setInsureQuantity}
                    labels={whoToInsure}
                  />
                  <StepBirth
                    insureQuantity={insureQuantity}
                    // firstGender={sex}
                    insurerDetails={insurerDetails}
                    setInsurerDetails={setInsurerDetails}
                    labels={birthLabels}
                    errorMessage={errorMessage}
                  />
                </div>
              )}
              {step === 3 && (
                <StepResidence
                  residence={residence}
                  setResidence={setResidence}
                  placeholder={residencePlaceholder}
                  locale={locale}
                />
              )}
              {step === 4 && (
                <StepNonHK
                  form={form}
                  formError={formError}
                  labels={nonHKFormLabels}
                />
              )}
              {step === 5 && (
                <StepThankyou subtitle={thankSubtitle} content={thankContent} />
              )}
            </Progress>
          </div>

          {/* {(step === 4 || step === 6) && (
            <Banner props={toolData.banner} lang={lang} />
          )} */}
          {step === 5 && (
            <div className={`bg-ming`}>
              <Resources
                props={toolData.resources}
                categories={pageContext.categories}
                labels={keyLabels}
                lang={lang}
                bgColor={"bg-mint"}
              />
            </div>
          )}
          <div className={`overflow-hidden`}>
            <Pros
              props={generalData.sellingPoints}
              lang={lang}
              additionalClass={"nonHomepage"}
            />
            <Process
              props={generalData.process}
              lang={lang}
              additionalClass={"nonHomepage"}
            />
            <Partners props={homepageData.partners} lang={lang} />
            <Testimonial props={homepageData.testimony} lang={lang} />
          </div>
        </div>
      </div>
    </Layout>
  )
}
export default QuotationPage
