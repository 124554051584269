import * as style from "./Progress.module.scss"

import React from "react"
import Dash from "../Dash"
import Button from "../Button"
import { staticText } from "../../enumerator/staticText"

const Progress = props => {
  const {
    step,
    updateStep,
    insureQuantity,
    progressTitle,
    submitForm,
    labels,
    children,
  } = props

  const { backLabel, nextLabel, submitLabel, applyNowLabel, insureTeamButton } =
    labels

  const goNextStep = () =>
    step === 0 || step <= 3
      ? updateStep(step + 1)
      : step === 4
      ? submitForm(staticText.NONHK)
      : null

  const goPreviousStep = () =>
    step === 6 ? updateStep(3) : updateStep(step - 1)

  const getProgress = () => step * 33.3 + 10
  const getNextLabel = () =>
    step <= 3 ? nextLabel : step === 4 ? submitLabel : applyNowLabel

  return (
    <div className={`${style.quotationProgress} container`}>
      <div className="row d-flex">
        <h2 className={`ming`}>{progressTitle}</h2>
        <div className="d-flex flex-row">
          <div className="col-lg-6 d-lg-block d-none">
            <Dash align="left" />
          </div>
          {/* </div> */}
          {step <= 3 && (
            <div className={`${style.quotationProgressBar} col-lg-6 col-12`}>
              <div className={`${style.progress} progress`}>
                <div
                  className={`${style.progressBar} progress-bar`}
                  role="progressbar"
                  style={{ width: getProgress() + "%" }}
                  aria-valuenow={getProgress()}
                  aria-valuemin="0"
                  aria-valuemax="100"
                  aria-label="progress"
                ></div>
              </div>
            </div>
          )}
        </div>
      </div>

      <div className="children">{children}</div>

      {step !== 5 && (
        <div className="d-flex flex-column">
          <div
            className={`
          ${step === 1 ? "align-self-lg-start" : "align-self-lg-end"} 
          ${step === 2 && insureQuantity === 8 ? "visible" : "invisible"}
          align-self-center order-lg-0 order-1`}
          >
            <Button
              type="readMore"
              text={insureTeamButton.label}
              url={insureTeamButton.url}
            />
          </div>
          <div
            className={`row order-lg-1 order-0 text-lg-start text-center d-flex flex-lg-row flex-column  ${style.quotationProgressControl}`}
          >
            <span className="col-lg-6 col-12 px-0 order-lg-0 order-1 mt-lg-0 mt-3">
              {step > 0 && (
                <Button
                  type="secondary"
                  text={backLabel}
                  event={goPreviousStep}
                />
              )}
            </span>

            <span className="col-lg-6 col-12 text-lg-end px-0 order-lg-1 order-0">
              <Button type="primary" text={getNextLabel()} event={goNextStep} />
            </span>
          </div>
        </div>
      )}
    </div>
  )
}
export default Progress
