import * as style from "./Quantity.module.scss"

import React, { useRef, useEffect } from "react"
import { getBackgroundImage } from "../../plugins/common"
import Slider from "@mui/material/Slider"
import { makeStyles, createStyles } from "@mui/styles"

const StepQuantity = props => {
  let { insureQuantity, setInsureQuantity, labels } = props

  const iconQuantity = {
    icon: [
      {
        qte: 1,
        src: labels.myselfImage,
        label: labels.myselfLabel,
      },
      {
        qte: 2,
        src: labels.partnerImage,
        label: labels.partnerLabel,
      },
    ],
    default: {
      src: labels.familyImage,
      label: labels.familyLabel,
    },
  }

  // const MINIMUM_VAlUE = 1
  // const MAXIMUM_VAlUE = 8

  const winRef = useRef(null)

  useEffect(() => {
    winRef.current.scrollIntoView({ block: "start" })
  }, [])
  // const getPercentage = val =>
  //   (val - 1) * (100 / (MAXIMUM_VAlUE - MINIMUM_VAlUE))

  // const getThumbStyle = val => {
  //   return {
  //     left: `${getPercentage(val)}%`,
  //     transform: `translate(-${getPercentage(val)}%, -50%)`,
  //   }
  // }
  // const getTrackInnerStyle = val => {
  //   return {
  //     width: `${getPercentage(val)}%`,
  //   }
  // }

  // const [thumbStyle, setThumbStyle] = useState(getThumbStyle(insureQuantity))
  // const [trackInnerStyle, setTrackInnerStyle] = useState(
  //   getTrackInnerStyle(insureQuantity)
  // )
  // const updateSlider = (event, newValue) => {
  //   // const value = event.target.value
  //   console.log("newValue: ", newValue)
  //   console.log("event: ", event)
  //   setInsureQuantity(newValue)
  //   // setThumbStyle(getThumbStyle(newValue))
  //   // setTrackInnerStyle(getTrackInnerStyle(newValue))
  // }

  const getByQuantity = qte =>
    iconQuantity.icon.find(i => i.qte === qte) ?? iconQuantity.default
  const getLabelbyQuantity = qte => getByQuantity(qte).label
  const getIconByQuantity = qte => getByQuantity(qte).src
  const valueLabelFormat = () => {
    return getLabelbyQuantity(insureQuantity)
  }
  const useStyles = makeStyles(theme =>
    createStyles({
      root: {
        color: "#0a7e84 !important",
        height: "3px !important",
        // marginRight: " 100px !important",
      },
      track: {
        border: "none",
      },
      thumb: {
        height: "20px !important",
        width: "20px !important",
        backgroundColor: "#0a7e84 !important",
      },
      valueLabel: {
        lineHeight: "0px !important",
        fontSize: "16px !important",
        background: "unset !important",
        padding: "0px !important",
        width: "90px !important",
        height: "90px !important",
        color: "#2f5a5c  !important",
        backgroundImage: `url(${getBackgroundImage(
          getIconByQuantity(insureQuantity)
        )}) !important`,
        backgroundSize: "contain !important",
        backgroundPosition: "bottom !important",
        backgroundRepeat: "no-repeat !important",
        objectFit: "contain !important",
        paddingLeft: "50px !important",
        paddingRight: "50px !important",
        // display: "block !important",
        paddingBottom: "110px !important",
        textAlign: "center !important",
      },
      valueLabelLabel: {
        display: "block !important",
        paddingBottom: "120px !important",
      },
    })
  )

  const sliderClass = useStyles()

  // const marks = [
  //   { value: 1, label: "1" },
  //   { value: 2, label: "2" },
  //   { value: 3, label: "3" },
  //   { value: 4, label: "4" },
  //   { value: 5, label: "5" },
  //   { value: 6, label: "6" },
  //   { value: 7, label: "7" },
  //   { value: 8, label: "8" },
  // ]

  return (
    <div className={`${style.quotationQuantity} container`}>
      <span
        className={`position-absolute`}
        style={{ top: -300 }}
        ref={winRef}
      ></span>
      <div className={`row ${style.sliderRow}`}>
        {/* number of people to insure */}
        <div className={`offset-1 col-lg-9 col-9 row align-content-center`}>
          <div className="col-lg-12 col-sm-12">
            <Slider
              classes={sliderClass}
              aria-label="QuantitySlider"
              defaultValue={labels.minimum}
              valueLabelDisplay="on"
              valueLabelFormat={valueLabelFormat}
              value={insureQuantity}
              onChange={(e, v) => setInsureQuantity(v)}
              marks
              step={1}
              min={labels.minimum}
              max={labels.maximum}
            />
            {/* <div className={`${style.wrap} ${style.teamWrap}`}>
              <input
                type="range"
                id="nbPeopleInsure"
                className={style.range}
                min={MINIMUM_VAlUE}
                max={MAXIMUM_VAlUE}
                step="1"
                onInput={e => updateSlider(e.target.value)}
                
              />
              <div className={style.track}>
                <div
                  className={style.trackInner}
                  id="track-inner"
                  style={trackInnerStyle}
                ></div>
              </div>
              <div
                id="thumbZone"
                className={`${style.thumbZone} position-relative`}
              >
                <div className={style.thumb} id="thumb" style={thumbStyle}>
                  <div className={style.insureGroupLabelRow}>
                    <div
                      className={`position-relative ${style.glWrapper} d-flex flex-column`}
                    >
                      <div className={style.insureGroupLabel}>
                        {getLabelbyQuantity(insureQuantity)}
                      </div>
                    </div>
                  </div>
                  <div className={`${style.greenIcon} position-absolute`}>
                    <GatsbyImage
                      sizes="(max-width: 370px) 37w, (max-width: 500px) 50vw,(max-width: 800px) 80vw,  100vw"
                      image={getGatsbyImage(getIconByQuantity(insureQuantity))}
                      alt="alea"
                    />
                  </div>

                  <div className={`${style.thumbValue} position-absolute`}>
                    {insureQuantity}
                  </div>
                </div>
              </div>
            </div> */}
          </div>
        </div>
        {/* <div
          className={`text-start align-self-center grey-text col-lg-2 col-1 ${
            style.maxValue
          } ${insureQuantity === MAXIMUM_VAlUE ? "invisible" : "visible"}`}
        >
          {MAXIMUM_VAlUE}
        </div> */}
      </div>
    </div>
  )
}
export default StepQuantity
