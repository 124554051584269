// extracted by mini-css-extract-plugin
export var alertRed = "Benefits-module--alert-red--tOrFT";
export var benefitImage = "Benefits-module--benefitImage--4G8vB";
export var bgBarGrey = "Benefits-module--bg-bar-grey--ZqoCl";
export var bgBeige = "Benefits-module--bg-beige--Fz9KT";
export var bgCopper = "Benefits-module--bg-copper--OH4Zh";
export var bgDaffodil = "Benefits-module--bg-daffodil--xJdSf";
export var bgGreyText = "Benefits-module--bg-grey-text--bTS3N";
export var bgMing = "Benefits-module--bg-ming--G8R4A";
export var bgMint = "Benefits-module--bg-mint--XbIGs";
export var bgRed = "Benefits-module--bg-red--LZPoH";
export var bgRedTrans = "Benefits-module--bg-red-trans--NoqAh";
export var bgSand = "Benefits-module--bg-sand--0e3zq";
export var bgSoftSun = "Benefits-module--bg-softSun--RfiKO";
export var bgTeal = "Benefits-module--bg-teal--OAK82";
export var bgWhite = "Benefits-module--bg-white--Nc-Td";
export var bgWomenSharp = "Benefits-module--bg-women-sharp--n2iFp";
export var blackText = "Benefits-module--black-text--lxTJX";
export var checkbox = "Benefits-module--checkbox--K2ovn";
export var checked = "Benefits-module--checked--Xvr1G";
export var copper = "Benefits-module--copper--gFc2J";
export var corners = "Benefits-module--corners--sEcUW";
export var cursorNormal = "Benefits-module--cursor-normal--QH+bD";
export var cursorPointer = "Benefits-module--cursor-pointer--JaQCt";
export var dUnset = "Benefits-module--d-unset--nbI2X";
export var darkGrey = "Benefits-module--dark-grey--h6gOp";
export var dash = "Benefits-module--dash--y68QA";
export var flex = "Benefits-module--flex--7yHCS";
export var flex1 = "Benefits-module--flex-1--3ZK0t";
export var fontSize12 = "Benefits-module--font-size-12--PNFcA";
export var fontSize20 = "Benefits-module--font-size-20--zkuwy";
export var greyPlaceholder = "Benefits-module--grey-placeholder--AF1M6";
export var greyText = "Benefits-module--grey-text--+oInR";
export var h1sizing = "Benefits-module--h1sizing--q1CUz";
export var h2sizing = "Benefits-module--h2sizing--5ioRH";
export var hidden = "Benefits-module--hidden--qzgmK";
export var image = "Benefits-module--image--6zCuy";
export var imageAni = "Benefits-module--imageAni--u4+Uo";
export var imageZoom = "Benefits-module--imageZoom--2m2pe";
export var imageZoomGatsby = "Benefits-module--imageZoomGatsby--NwT5D";
export var info = "Benefits-module--info--S36CA";
export var italic = "Benefits-module--italic--mgNCt";
export var label = "Benefits-module--label--9cNHo";
export var lightGrey = "Benefits-module--light-grey--JReCg";
export var logo = "Benefits-module--logo--RKJGp";
export var lora = "Benefits-module--lora--iIwdb";
export var loraBold = "Benefits-module--lora-Bold--7T2+x";
export var loraBoldItalic = "Benefits-module--lora-BoldItalic--dBPZv";
export var loraMedium = "Benefits-module--lora-Medium--622L8";
export var loraSemiBold = "Benefits-module--lora-SemiBold--A5w60";
export var main = "Benefits-module--main--sEjNC";
export var ming = "Benefits-module--ming--LLCFC";
export var mingHover2 = "Benefits-module--ming-hover-2--zqv-8";
export var modalOpen = "Benefits-module--modal-open--KnEeR";
export var noPointer = "Benefits-module--noPointer--oX8Y8";
export var noUnderline = "Benefits-module--no-underline--TQGqJ";
export var openSans = "Benefits-module--openSans--QB6y0";
export var openSansBold = "Benefits-module--openSans-Bold--tGBcn";
export var openSansSemiBold = "Benefits-module--openSans-SemiBold--Etynu";
export var quotation = "Benefits-module--quotation--Fx2B1";
export var quotationBenefits = "Benefits-module--quotationBenefits--u496E";
export var quotationCard = "Benefits-module--quotationCard--MXhMF";
export var round = "Benefits-module--round--cQtAa";
export var roundCorners = "Benefits-module--round-corners--QaBYH";
export var selected = "Benefits-module--selected--vNiCX";
export var softCorners = "Benefits-module--soft-corners--dTEZ+";
export var softShadows = "Benefits-module--soft-shadows--fuRit";
export var softerCorners = "Benefits-module--softer-corners--FBl5q";
export var softyCorners = "Benefits-module--softy-corners--AVEDG";
export var subtitle = "Benefits-module--subtitle--GUfez";
export var tableShadow = "Benefits-module--table-shadow--Pj-eV";
export var teal = "Benefits-module--teal--lgP1D";
export var topNavShadow = "Benefits-module--top-nav-shadow--+-zL1";
export var uppercase = "Benefits-module--uppercase--pi-MT";
export var wFull = "Benefits-module--w-full--yG6x8";
export var white = "Benefits-module--white--Ktz5S";
export var womenRed = "Benefits-module--women-red---2jhn";
export var womenSharp = "Benefits-module--women-sharp---dsSQ";