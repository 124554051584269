import * as style from "./Residence.module.scss"

import React, { useRef, useEffect } from "react"
import { getCountryList } from "../../plugins/common"
import useAutocomplete from "@mui/material/useAutocomplete"

const StepResidence = props => {
  let { residence, setResidence, locale, placeholder } = props

  // const locale = "en"
  const countries = getCountryList(locale)
  // ISO 3166-1 alpha-2
  // ⚠️ No support for IE 11

  const {
    getRootProps,
    getInputProps,
    getListboxProps,
    getOptionProps,
    groupedOptions,
  } = useAutocomplete({
    id: "use-autocomplete-demo",
    options: countries,
    getOptionLabel: option => option,
    defaultValue: residence,
    onChange: (event, value) => {
      setResidence(value)
    },
  })

  const winRef = useRef(null)

  useEffect(() => {
    winRef.current.scrollIntoView({ block: "start" })
  }, [])

  return (
    <div
      className={`${style.quotationResidence} ps-0 text-lg-start text-center`}
    >
      <span
        className={`position-absolute`}
        style={{ top: -300 }}
        ref={winRef}
      ></span>
      <div {...getRootProps()}>
        <input
          type="text"
          className={style.quotation}
          placeholder={placeholder}
          {...getInputProps()}
        />
      </div>

      {groupedOptions.length ? (
        <div
          className={`position-relative mx-lg-0 mx-auto ${style.listWrapper}`}
        >
          <ul
            className={`${style.listbox} align-self-center`}
            {...getListboxProps()}
          >
            {groupedOptions.map((option, index) => (
              <li
                id={index}
                tabIndex={index}
                {...getOptionProps({ option, index })}
              >
                {option}
              </li>
            ))}
          </ul>
        </div>
      ) : null}
    </div>
  )
}
export default StepResidence
