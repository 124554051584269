import React from "react"
import QuotationForm from "./Form"

const StepNonHK = props => {
  const { form, formError, labels } = props

  const subtitle = labels.subtitle

  return (
    <div>
      <h3 className="ming mt-0">{subtitle}</h3>
      <QuotationForm
        form={form}
        errors={formError}
        isHalf={true}
        isCenter={true}
        labels={labels.fromLabels}
      />
    </div>
  )
}
export default StepNonHK
