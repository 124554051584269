import * as style from "./StepEmail.module.scss"

import React, { useRef, useEffect } from "react"
// import { handleEnterPress } from "../../plugins/common"
import Icon from "@mdi/react"
import { mdiCheckboxBlankOutline, mdiCheckboxMarked } from "@mdi/js"
import { CustomMarkdown } from "../../plugins/sanitizehtml"

const StepEmail = props => {
  let {
    email,
    setEmail,
    termsError,
    emailError,
    agreeTerms,
    setAgreeTerms,
    // locale,
    emailTnc,
    placeholder,
  } = props
  const winRef = useRef(null)

  useEffect(() => {
    winRef.current.scrollIntoView({ block: "start" })
  }, [])

  return (
    <div className={`ps-0 text-lg-start text-center ${style.stepEmail}`}>
      <span
        className={`position-absolute`}
        style={{ top: -300 }}
        ref={winRef}
      ></span>
      <input
        type="email"
        className={`${style.quotation} ${emailError ? style.error : ""}`}
        placeholder={placeholder}
        value={email}
        onChange={e => setEmail(e.target.value)}
        // onKeyPress={e => handleEnterPress(e,)}
      />
      <div
        className={`${
          !agreeTerms && termsError ? "alert-red" : "ming"
        } flex justify-content-start align-items-center mt-3`}
        tabIndex={-1}
        onClick={() => setAgreeTerms(!agreeTerms)}
        onKeyDown={() => setAgreeTerms(!agreeTerms)}
        role="button"
      >
        <Icon
          className={`checkbox me-2`}
          path={agreeTerms ? mdiCheckboxMarked : mdiCheckboxBlankOutline}
          size={1}
        />
        <CustomMarkdown html={emailTnc} classnames="" color="copper" />
      </div>
    </div>
  )
}
export default StepEmail
