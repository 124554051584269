// extracted by mini-css-extract-plugin
export var alertRed = "Residence-module--alert-red--MKDLp";
export var bgBarGrey = "Residence-module--bg-bar-grey--dUPBn";
export var bgBeige = "Residence-module--bg-beige--TDS9S";
export var bgCopper = "Residence-module--bg-copper--NMrLp";
export var bgDaffodil = "Residence-module--bg-daffodil--KwE7u";
export var bgGreyText = "Residence-module--bg-grey-text--m3T4M";
export var bgMing = "Residence-module--bg-ming---zXYC";
export var bgMint = "Residence-module--bg-mint--6t8OK";
export var bgRed = "Residence-module--bg-red---4ADu";
export var bgRedTrans = "Residence-module--bg-red-trans--RCzdq";
export var bgSand = "Residence-module--bg-sand--Xk3zC";
export var bgSoftSun = "Residence-module--bg-softSun--fYSpV";
export var bgTeal = "Residence-module--bg-teal--bZQHj";
export var bgWhite = "Residence-module--bg-white--w3r+z";
export var bgWomenSharp = "Residence-module--bg-women-sharp--uCmAL";
export var blackText = "Residence-module--black-text--WqF2k";
export var copper = "Residence-module--copper--69pTv";
export var corners = "Residence-module--corners--MxhkU";
export var cursorNormal = "Residence-module--cursor-normal--N8cBE";
export var cursorPointer = "Residence-module--cursor-pointer--RMKPj";
export var dUnset = "Residence-module--d-unset--PUZXN";
export var darkGrey = "Residence-module--dark-grey--ntDxe";
export var dash = "Residence-module--dash--sE5br";
export var flex = "Residence-module--flex--cj7g0";
export var flex1 = "Residence-module--flex-1--DBIHS";
export var fontSize12 = "Residence-module--font-size-12--MBJPZ";
export var fontSize20 = "Residence-module--font-size-20--57wW9";
export var greyPlaceholder = "Residence-module--grey-placeholder--fcp0t";
export var greyText = "Residence-module--grey-text--sgc3t";
export var h1sizing = "Residence-module--h1sizing--Hza7S";
export var h2sizing = "Residence-module--h2sizing--OYggO";
export var hidden = "Residence-module--hidden--c+aUG";
export var imageAni = "Residence-module--imageAni--N4Z6N";
export var imageZoom = "Residence-module--imageZoom--cpmH4";
export var imageZoomGatsby = "Residence-module--imageZoomGatsby--1Xnv1";
export var italic = "Residence-module--italic--LKPuC";
export var lightGrey = "Residence-module--light-grey--YIu2S";
export var listWrapper = "Residence-module--listWrapper--VZSFl";
export var listbox = "Residence-module--listbox--A9qan";
export var logo = "Residence-module--logo--gGx+P";
export var lora = "Residence-module--lora--hJ141";
export var loraBold = "Residence-module--lora-Bold--pVN7P";
export var loraBoldItalic = "Residence-module--lora-BoldItalic--oZTqC";
export var loraMedium = "Residence-module--lora-Medium--NHG7S";
export var loraSemiBold = "Residence-module--lora-SemiBold--ax5Xp";
export var main = "Residence-module--main--ekNLM";
export var ming = "Residence-module--ming--7PcVb";
export var mingHover2 = "Residence-module--ming-hover-2--RLVdB";
export var modalOpen = "Residence-module--modal-open--XNAeu";
export var noUnderline = "Residence-module--no-underline--MKHUJ";
export var openSans = "Residence-module--openSans--KyVHc";
export var openSansBold = "Residence-module--openSans-Bold--b2DVD";
export var openSansSemiBold = "Residence-module--openSans-SemiBold--oMgwR";
export var quotation = "Residence-module--quotation--ctI8O";
export var quotationResidence = "Residence-module--quotationResidence--vWfK5";
export var round = "Residence-module--round--Iy8hL";
export var roundCorners = "Residence-module--round-corners--Tq6Tl";
export var softCorners = "Residence-module--soft-corners--8qgDA";
export var softShadows = "Residence-module--soft-shadows--Gbdbv";
export var softerCorners = "Residence-module--softer-corners--BNqLG";
export var softyCorners = "Residence-module--softy-corners--NnMO6";
export var tableShadow = "Residence-module--table-shadow--4l31u";
export var teal = "Residence-module--teal--4on5r";
export var topNavShadow = "Residence-module--top-nav-shadow--++oL1";
export var uppercase = "Residence-module--uppercase--qhjKf";
export var wFull = "Residence-module--w-full--NNElF";
export var white = "Residence-module--white--kiFyZ";
export var womenRed = "Residence-module--women-red--Rpkwp";
export var womenSharp = "Residence-module--women-sharp--iyFzD";