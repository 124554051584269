import * as style from "./Benefits.module.scss"

import React from "react"
import { mdiCheckboxMarked, mdiCheckboxBlankOutline } from "@mdi/js"
import Icon from "@mdi/react"

import { GatsbyImage, StaticImage } from "gatsby-plugin-image"
import { getGatsbyImage } from "../../plugins/common"

const StepBenefits = props => {
  let { benefitList, updateBenefits } = props

  return (
    <div className={`${style.quotationBenefits} container`}>
      <div className="row d-flex justify-content-between justify-content-lg-start">
        {benefitList.map((benef, index) => (
          <div
            className={`card ${
              style.quotationCard
            } container position-relative me-lg-2 me-2 d-flex flex-lg-column flex-row ${
              benef.selected ? style.selected : ""
            } ${index === 0 ? style.noPointer : ""}`}
            role="button"
            onClick={() => updateBenefits(index)}
            onKeyDown={() => updateBenefits(index)}
            key={index}
            tabIndex={0}
          >
            {benef.selected && (
              <StaticImage
                sizes="(max-width: 370px) 37w, (max-width: 500px) 50vw,(max-width: 800px) 80vw,  100vw"
                className={`${style.checked} position-absolute d-lg-block d-none`}
                src="../../images/get-a-quote/checked.webp"
                alt="checked"
                width={25}
              />
            )}
            <div
              className={`flex-lg-1 flex-1 col-lg-12 col-3 text-lg-center ${style.image}`}
            >
              {/* <BenefitIcon
                id={benef.pipedriveId}
                included={true}
                isCard={true}
                inlineStyle={{ width: "60%", height: "auto", flex: "1" }}
              /> */}
              {/* <img
                src={benef.image}
                className={`${style.benefitImage} flex-1`}
              /> */}
              <GatsbyImage
                sizes="(max-width: 370px) 37w, (max-width: 500px) 50vw,(max-width: 800px) 80vw,  100vw"
                className={`${style.benefitImage} flex-1`}
                image={getGatsbyImage(benef.image)}
                alt="alea"
              />
            </div>

            {/* <StaticImage
              sizes="(max-width: 370px) 37w, (max-width: 500px) 50vw,(max-width: 800px) 80vw,  100vw"
              className={style.genderImage}
              image={benef.image}
              alt="alea"
            /> */}
            <div
              className={`flex-lg-1 d-flex col-lg-12 col-6 justify-content-lg-center ms-lg-0 ms-3 ${style.info}`}
            >
              <div className="ming align-self-lg-center text-lg-center">
                <div className={style.label}>{benef.title}</div>
                {benef.subtitle && (
                  <div className={style.subtitle}>{benef.subtitle}</div>
                )}
              </div>
            </div>
            <div className="d-block d-lg-none flex-1 col-3 text-center">
              {benef.selected ? (
                <Icon
                  className={style.checkbox}
                  path={mdiCheckboxMarked}
                  size={1}
                />
              ) : (
                <Icon
                  className={style.checkbox}
                  path={mdiCheckboxBlankOutline}
                  size={1}
                />
              )}
            </div>
          </div>
        ))}
      </div>
    </div>
  )
}
export default StepBenefits
