// extracted by mini-css-extract-plugin
export var alertRed = "Birth-module--alert-red--7nWOM";
export var bgBarGrey = "Birth-module--bg-bar-grey--U3NNl";
export var bgBeige = "Birth-module--bg-beige--yVnNo";
export var bgCopper = "Birth-module--bg-copper--AxdIO";
export var bgDaffodil = "Birth-module--bg-daffodil--994Sw";
export var bgGreyText = "Birth-module--bg-grey-text--ym4zs";
export var bgMing = "Birth-module--bg-ming--kBHam";
export var bgMint = "Birth-module--bg-mint--e25Zf";
export var bgRed = "Birth-module--bg-red--zdySh";
export var bgRedTrans = "Birth-module--bg-red-trans--8aoVx";
export var bgSand = "Birth-module--bg-sand--dfJSV";
export var bgSoftSun = "Birth-module--bg-softSun--XsbjZ";
export var bgTeal = "Birth-module--bg-teal--JrZCJ";
export var bgWhite = "Birth-module--bg-white--t8fLb";
export var bgWomenSharp = "Birth-module--bg-women-sharp--7QPU2";
export var birthday = "Birth-module--birthday--oIWgE";
export var birthdayError = "Birth-module--birthdayError--T-SAg";
export var blackText = "Birth-module--black-text--+jbGw";
export var copper = "Birth-module--copper--m5+1J";
export var corners = "Birth-module--corners--poYxQ";
export var cursorNormal = "Birth-module--cursor-normal--fzR+h";
export var cursorPointer = "Birth-module--cursor-pointer--zcy+o";
export var dUnset = "Birth-module--d-unset--hjUTh";
export var darkGrey = "Birth-module--dark-grey--SlCY5";
export var dash = "Birth-module--dash---GVYM";
export var dateInput = "Birth-module--dateInput--5bH6k";
export var datepicker = "Birth-module--datepicker--KV-GR";
export var detailRow = "Birth-module--detailRow--AzJOL";
export var errorMsg = "Birth-module--errorMsg--9qWEJ";
export var femaleIcon = "Birth-module--femaleIcon--b3M+3";
export var flex = "Birth-module--flex--v8Em9";
export var flex1 = "Birth-module--flex-1--KfoWW";
export var fontSize12 = "Birth-module--font-size-12--J6p3h";
export var fontSize20 = "Birth-module--font-size-20--JJu4N";
export var fullWidth = "Birth-module--fullWidth--2cWJO";
export var gender = "Birth-module--gender--PHnMG";
export var genderError = "Birth-module--genderError--r1T6H";
export var genderFemale = "Birth-module--genderFemale--ExMw2";
export var genderMale = "Birth-module--genderMale--yavr7";
export var genderSelect = "Birth-module--genderSelect--pwsC0";
export var greyPlaceholder = "Birth-module--grey-placeholder--vjXKn";
export var greyText = "Birth-module--grey-text--2aVBl";
export var h1sizing = "Birth-module--h1sizing--iEwYu";
export var h2sizing = "Birth-module--h2sizing--TljCI";
export var hidden = "Birth-module--hidden--Ni0eh";
export var imageAni = "Birth-module--imageAni--qdNv0";
export var imageZoom = "Birth-module--imageZoom--VxNWs";
export var imageZoomGatsby = "Birth-module--imageZoomGatsby--pBiqe";
export var italic = "Birth-module--italic--jH7bh";
export var lightGrey = "Birth-module--light-grey--MGnXE";
export var logo = "Birth-module--logo--uaUgU";
export var lora = "Birth-module--lora--X+HFi";
export var loraBold = "Birth-module--lora-Bold--qVlT+";
export var loraBoldItalic = "Birth-module--lora-BoldItalic--mtO3L";
export var loraMedium = "Birth-module--lora-Medium--UvigH";
export var loraSemiBold = "Birth-module--lora-SemiBold--CPEac";
export var main = "Birth-module--main--3ctrI";
export var maleIcon = "Birth-module--maleIcon--tue8w";
export var ming = "Birth-module--ming--FLmhz";
export var mingHover2 = "Birth-module--ming-hover-2--TNhtW";
export var modalOpen = "Birth-module--modal-open--R9r-8";
export var noUnderline = "Birth-module--no-underline--XFMQm";
export var openSans = "Birth-module--openSans--0WAL-";
export var openSansBold = "Birth-module--openSans-Bold--kKiNN";
export var openSansSemiBold = "Birth-module--openSans-SemiBold--7hfS9";
export var quotation = "Birth-module--quotation--uKIhc";
export var quotationBirth = "Birth-module--quotationBirth--68W7v";
export var rightBorder = "Birth-module--rightBorder--GycwC";
export var round = "Birth-module--round--E-xN9";
export var roundCorners = "Birth-module--round-corners--GZvEv";
export var selected = "Birth-module--selected--RYsUa";
export var slash = "Birth-module--slash--dUjK1";
export var smallerBox = "Birth-module--smallerBox--Uz9H2";
export var softCorners = "Birth-module--soft-corners--8MFb2";
export var softShadows = "Birth-module--soft-shadows--iwWaY";
export var softerCorners = "Birth-module--softer-corners--0hBzQ";
export var softyCorners = "Birth-module--softy-corners--XxTBA";
export var tableShadow = "Birth-module--table-shadow--EjmpZ";
export var teal = "Birth-module--teal--KoUyE";
export var topNavShadow = "Birth-module--top-nav-shadow--SzyLc";
export var unselected = "Birth-module--unselected--usQoA";
export var uppercase = "Birth-module--uppercase--K3mzb";
export var wFull = "Birth-module--w-full--yNu81";
export var white = "Birth-module--white--vSaEf";
export var womenRed = "Birth-module--women-red--8A2LO";
export var womenSharp = "Birth-module--women-sharp--k7irm";