// extracted by mini-css-extract-plugin
export var alertRed = "quotation-module--alert-red--WVz4w";
export var bgBarGrey = "quotation-module--bg-bar-grey--6dhTV";
export var bgBeige = "quotation-module--bg-beige--1J0ci";
export var bgCopper = "quotation-module--bg-copper--nvqzJ";
export var bgDaffodil = "quotation-module--bg-daffodil--f8fOe";
export var bgGreyText = "quotation-module--bg-grey-text--rQ4VZ";
export var bgMing = "quotation-module--bg-ming--uZQJm";
export var bgMint = "quotation-module--bg-mint--2K3N1";
export var bgRed = "quotation-module--bg-red--CY+Sz";
export var bgRedTrans = "quotation-module--bg-red-trans--qznY-";
export var bgSand = "quotation-module--bg-sand--RbeRd";
export var bgSoftSun = "quotation-module--bg-softSun--knooU";
export var bgTeal = "quotation-module--bg-teal--JwHCW";
export var bgWhite = "quotation-module--bg-white--GtK8e";
export var bgWomenSharp = "quotation-module--bg-women-sharp--e+uG4";
export var blackText = "quotation-module--black-text--4h730";
export var content = "quotation-module--content--Swk6R";
export var copper = "quotation-module--copper--udk6c";
export var corners = "quotation-module--corners--eyhQA";
export var cursorNormal = "quotation-module--cursor-normal--KwKzf";
export var cursorPointer = "quotation-module--cursor-pointer--6LQuL";
export var dUnset = "quotation-module--d-unset--4aAsO";
export var darkGrey = "quotation-module--dark-grey--09bC+";
export var dash = "quotation-module--dash--uj9EF";
export var errorMsg = "quotation-module--errorMsg--XXtJs";
export var faqBody = "quotation-module--faq-body--q-29v";
export var flex = "quotation-module--flex--KtGCb";
export var flex1 = "quotation-module--flex-1--tuUOI";
export var fontSize12 = "quotation-module--font-size-12--WyxFP";
export var fontSize20 = "quotation-module--font-size-20--ctW1x";
export var glossaryItem = "quotation-module--glossary-item--f2iQF";
export var glossaryList = "quotation-module--glossary-list--VaO3J";
export var greyPlaceholder = "quotation-module--grey-placeholder--axh5Z";
export var greyText = "quotation-module--grey-text--Pa5Wo";
export var h1sizing = "quotation-module--h1sizing--noMi4";
export var h2sizing = "quotation-module--h2sizing--3LPPa";
export var header = "quotation-module--header--XoqcZ";
export var hidden = "quotation-module--hidden--Ey4gq";
export var imageAni = "quotation-module--imageAni--+O1Jk";
export var imageZoom = "quotation-module--imageZoom--VPsqr";
export var imageZoomGatsby = "quotation-module--imageZoomGatsby--OLJVc";
export var italic = "quotation-module--italic--scMiG";
export var left = "quotation-module--left--Crsgw";
export var leftCol = "quotation-module--left-col--DAuWO";
export var leftEmpty = "quotation-module--leftEmpty--wZEP4";
export var leftFull = "quotation-module--leftFull--OZ8xu";
export var lightGrey = "quotation-module--light-grey--hk3eI";
export var logo = "quotation-module--logo--YLT71";
export var lora = "quotation-module--lora--dUVjy";
export var loraBold = "quotation-module--lora-Bold--y8maH";
export var loraBoldItalic = "quotation-module--lora-BoldItalic--iRvnM";
export var loraMedium = "quotation-module--lora-Medium--Djeww";
export var loraSemiBold = "quotation-module--lora-SemiBold--f-NAn";
export var main = "quotation-module--main--udMB-";
export var ming = "quotation-module--ming--DFlY7";
export var mingHover2 = "quotation-module--ming-hover-2--uWmIe";
export var modalOpen = "quotation-module--modal-open--a9nRz";
export var noUnderline = "quotation-module--no-underline--oyIsA";
export var openSans = "quotation-module--openSans--Esz0x";
export var openSansBold = "quotation-module--openSans-Bold--7Tyx4";
export var openSansSemiBold = "quotation-module--openSans-SemiBold--BrImN";
export var quotation = "quotation-module--quotation--WeUnz";
export var quotationBody = "quotation-module--quotationBody--gBmhU";
export var right = "quotation-module--right--PwYZ3";
export var round = "quotation-module--round--fr1jU";
export var roundCorners = "quotation-module--round-corners--1zT1M";
export var softCorners = "quotation-module--soft-corners--aoB7S";
export var softShadows = "quotation-module--soft-shadows--iliFc";
export var softerCorners = "quotation-module--softer-corners--qrlu2";
export var softyCorners = "quotation-module--softy-corners--4H0Kg";
export var subtitle = "quotation-module--subtitle--8GoKi";
export var tableShadow = "quotation-module--table-shadow--2BDZy";
export var teal = "quotation-module--teal--azKcd";
export var topNavShadow = "quotation-module--top-nav-shadow--YChJ+";
export var uppercase = "quotation-module--uppercase--OHolr";
export var wFull = "quotation-module--w-full--tl9Ox";
export var white = "quotation-module--white--fp64H";
export var womenRed = "quotation-module--women-red--gbJZn";
export var womenSharp = "quotation-module--women-sharp--4Kbyt";