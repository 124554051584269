import * as style from "./Birth.module.scss"

import React, { useEffect } from "react"

import { GatsbyImage } from "gatsby-plugin-image"
import { gender } from "../../enumerator/gender"
import { birthdayMasking, getGatsbyImage } from "../../plugins/common"

const StepBirth = props => {
  let {
    insureQuantity,
    // firstGender,
    insurerDetails,
    setInsurerDetails,
    labels,
    errorMessage,
  } = props

  const { dateOfBirthLabel, genderLabel, personLabel, maleIcon, femaleIcon } =
    labels

  const selectGender = (index, gender) => {
    insurerDetails[index].gender = gender
    const newInsurerDetails = JSON.parse(JSON.stringify(insurerDetails))
    setInsurerDetails(newInsurerDetails)
  }

  useEffect(() => {
    const insurerList = []
    for (let i = 0; i < insureQuantity; i++) {
      const singleBirth = {
        dd: "",
        mm: "",
        yyyy: "",
        birthday: "",
        gender: null,
      }
      if (insurerDetails[i]) {
        insurerList.push(insurerDetails[i])
        continue
      }
      // if (i === 0) singleBirth.gender = firstGender
      insurerList.push(singleBirth)
    }
    setInsurerDetails(insurerList)
  }, [insureQuantity])

  return (
    <div className={`${style.quotationBirth} container`}>
      <div
        className={`${style.errorMsg} ${
          errorMessage.length > 0 ? "visible" : "invisible"
        }`}
      >
        {errorMessage}
      </div>
      <div className="row ming lora-Bold mb-2">
        <div className="offset-lg-2 col-lg-7 col-9 row">{dateOfBirthLabel}</div>
        <div className="col-3 col-lg-3 ps-lg-0 pe-0">{genderLabel}</div>
      </div>
      {insurerDetails.map((item, index) => (
        <div
          className={`row ming ${style.detailRow}  mt-lg-0 ${
            index === 0 ? "mt-0" : "mt-5"
          } ${item.birthdayError ? style.birthdayError : ""}
          ${item.genderError ? style.genderError : ""}`}
          key={index}
        >
          <div className="col-lg-2 col-12 align-self-lg-center ps-0">
            {personLabel} {index + 1}
          </div>
          <div className={`col-lg-7 col-9 row ${style.birthday} `}>
            <input
              type="text"
              className={`${style.datepicker}`}
              id="birthdate"
              placeholder="DD/MM/YYYY"
              defaultValue={item.birthday}
              onChange={e => {
                const newVal = birthdayMasking(e)
                item.birthday = newVal
              }}
            />
          </div>
          <div className="col-3 col-lg-3">
            <div className={`${style.genderSelect} d-flex`}>
              <div
                className={`${style.gender} ${style.genderFemale} ${
                  item.gender === gender.female && style.selected
                } ${item.gender === null && style.unselected}`}
                onClick={() => selectGender(index, gender.female)}
                onKeyDown={() => selectGender(index, gender.female)}
                role="button"
                tabIndex={0}
              >
                <GatsbyImage
                  sizes="(max-width: 370px) 37w, (max-width: 500px) 50vw,(max-width: 800px) 80vw,  100vw"
                  className={`${style.femaleIcon}`}
                  image={getGatsbyImage(femaleIcon)}
                  alt="female"
                />
              </div>
              <div
                className={`${style.gender} ${style.genderMale} ${
                  item.gender === gender.male && style.selected
                }`}
                onClick={() => selectGender(index, gender.male)}
                onKeyDown={() => selectGender(index, gender.male)}
                role="button"
                tabIndex={-1}
              >
                <GatsbyImage
                  sizes="(max-width: 370px) 37w, (max-width: 500px) 50vw,(max-width: 800px) 80vw,  100vw"
                  className={`${style.maleIcon}`}
                  image={getGatsbyImage(maleIcon)}
                  alt="male"
                />
              </div>
            </div>
          </div>
        </div>
      ))}
    </div>
  )
}
export default StepBirth
